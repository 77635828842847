/**
 * Slidey Slide
 */
/* jslint browser: true, undef:true, unused:true, laxbreak:true, loopfunc:true*/
/* global define, module, ender */

(function(win, doc) {
  'use strict';
	var SlideySlide = SlideySlide || function() {
    let cache = {
      timer: null,
      slideys: null,
      $body: null,
      $window: null,
    };
    let settings = {
      adjustBackgroundPosition: true,
      full: false,
      isIE: false,
    };

    const utils = {
      run: () => {
        const top = doc.scrollingElement ? doc.scrollingElement.scrollTop : doc.documentElement.scrollTop;
        if (!cache.slideys) {
          cache.slideys = $('.slidey-slide');
        }
        let $body = cache.$body;
        let rate = $body.data('slidey-slide-rate');
        let defaultOffset = $body.data('slidey-slide-offset');
        const windowHeight = cache.$window.height();
        const windowWidth = cache.$window.width();
        const documentHeight = $(doc).height();

        if (settings.full) {
            let shift = 0
            $('.slidey-slide-full-content').each(function(index, element){
                const offset = $(element).offset().top
                const height = $(element).outerHeight()
                if ((top + windowHeight) > offset) {
                    shift = (top - offset) * -0.1 - (offset < 500 ? 200 : 0)
                    return;
                }
            })
            cache.$body.css('background-position-y', shift + 'px');
        }

        cache.slideys.each(function(index, element) {
            const $this = $(element)
            if (!$this.parent().length) return
            // FIXME: Disable for IE
            if (settings.isIE) {
                $this.find('.slidey-slide-image').css('background-attachment', 'scroll')
            }
            const fixed = $this.find('.slidey-slide-image').css('background-attachment') == 'fixed';
            if (rate === undefined) {
                rate = fixed ? 0.1 : 0.1;
            }
            const heightRatio = fixed ? 2 : 1.2;
            const height = $this.outerHeight();
            const width = $this.outerWidth();
            if (defaultOffset === undefined) {
                defaultOffset = fixed ? -(windowHeight - height) * 0.01 : height * 2;
            }
            const limit = -height * rate + defaultOffset;
            const elementTop = $this.parent().is('.carousel') ? $this.parent().offset().top : $this.offset().top;
            const $image = $this.find('.slidey-slide-image');
            const offset = fixed ?
                Math.min((elementTop - top) * rate - defaultOffset, defaultOffset * rate) :
                Math.min((elementTop - top - height * 0.02) * rate, defaultOffset * rate);
            if (fixed && settings.adjustBackgroundPosition && $this.is('.hero-slide')) {
                const slides = $this.closest('.slick-slider').data('slides-to-show')
                const slideIndex = $this.parent().find('.hero-slide').index($this)
                const imageWidth = slides == 1 ? 1920 : height * 16 / 9;
                $image.css('background-position-x',
                    (slideIndex * width - (imageWidth - width) / 2) + 'px')
            }
            $image.css({
                'transform': 'translate3d(0px, ' + offset + 'px, 0px)',
                'height': (height - offset * heightRatio) + 'px'
            });
            if ((top - elementTop) < height) {
                $this.next().find('.slidey-slide-overlay-content').css({
                    'height': (Math.min(windowHeight, documentHeight) - height + offset + 50) + 'px',
                    'display': 'block',
                })
                $image.css({
                    'display': 'block',
                })
            } else {
                $image.css({
                    'display': 'none',
                })
                $this.next().find('.slidey-slide-overlay-content').css({
                    'display': 'none',
                })
            }
        });
        clearTimeout(cache.timer);
        cache.timer = null;
      },
      trigger: () => {
        utils.run();
        if (cache.timer) {
            clearTimeout(cache.timer)
        }
        cache.timer = setTimeout(utils.run, 500)
      },
    };

    const init = () => {
        settings.isIE = (navigator.userAgent.match(/MSIE [0-9]+/i)
            || navigator.userAgent.match(/Trident\/[0-9]+\./))

        if (settings.isIE
            || navigator.userAgent.match(/Edge\/[0-9]+\./)
            || navigator.userAgent.match(/Firefox/))
        {
            settings.adjustBackgroundPosition = false;
        }
        cache.$body = $('body');
        cache.$window = $(win);
        var u = $('[data-slidey-slide-full-url]').data('slidey-slide-full-url');
        if (typeof u != 'undefined') {
            cache.$body.addClass('slidey-slide-full').css('background-image', 'url(' + u + ')');
            settings.full = true;
        }
        utils.trigger();

        $(doc).scroll(utils.trigger);
        $(win).resize(utils.trigger);
    };

    init();
  };

  if ((typeof module !== 'undefined') && module.exports) {
		module.exports = SlideySlide;
	}
  if (typeof ender === 'undefined') {
		this.SlideySlide = SlideySlide;
	}
	if ((typeof define === "function") && define.amd) {
		define("SlideySlide", [], function() {
			return SlideySlide;
		});
	}

}).call(this, window, document);
