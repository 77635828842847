/*!
 * Start Bootstrap - Grayscale Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

import '../vendor/ytv/ytv';

(function($) {
  "use strict"; // Start of use strict

  // jQuery to collapse the navbar on scroll
  function collapseNavbar() {
    if ($(".navbar").offset().top > 50) {
      $(".fixed-top").addClass("top-nav-collapse")
    } else {
      $(".fixed-top").removeClass("top-nav-collapse")
    }
  }

  $(window).scroll(collapseNavbar)
  $(document).ready(collapseNavbar)
  // $(document).scroll(slideySlide.trigger)
  // $(document).ready(function(){setTimeout(slideySlide.init, 10)})

  // jQuery for page scrolling feature - requires jQuery Easing plugin
  $('a.page-scroll').bind('click', function(event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
          scrollTop: $($anchor.attr('href').replace('/', '')).offset().top
      }, 1500, 'easeInOutExpo')
      event.preventDefault()
  })

  // Closes the Responsive Menu on Menu Item Click
  $('.navbar-main-collapse ul li a').click(function() {
    if ($(this).attr('class') != 'dropdown-toggle active' && $(this).attr('class') != 'dropdown-toggle') {
      $('.navbar-toggler:visible').click();
    }
  });

  $('.navbar-main-collapse').bind('show.bs.collapse', event => {
    $(event.target).closest('.navbar-custom').addClass('expanded');
  });
  $('.navbar-main-collapse').bind('hidden.bs.collapse', event => {
    $(event.target).closest('.navbar-custom').removeClass('expanded');
  });

  $('body > nav .nav-item a[href="' + window.location.pathname + '"]').parent().addClass('active')

  $(window).on('load', function(){
    if ($('#youtube-playlist').length) {
      var controller = $('#youtube-playlist').ytv({
        //user: 'taperunsout',
        accent: '#337ab7',
        responsive: true,
        playlist: $('#youtube-playlist').data('playlist')
      });
    }
  })

})(jQuery); // End of use strict
