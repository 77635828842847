import 'jquery';
import 'jquery.easing';
import 'magnific-popup';
import 'jquery-lazy/jquery.lazy';
import 'jquery-lazy/jquery.lazy.plugins';
import 'tether';
import { tns } from 'tiny-slider';
import 'vide';
import 'mixitup';
import 'jquery-fancybox/source/js/jquery.fancybox';
import 'moment';
import 'eonasdan-bootstrap-datetimepicker';
import 'slick-carousel';
import 'paroller.js';
import 'tilt.js';
import Isotope from 'isotope-layout';

import 'fontawesome-free/css/fontawesome.css';
import 'fontawesome-free/css/brands.css';
import 'fontawesome-free/css/solid.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'html5-device-mockups/dist/device-mockups.css';
import 'tiny-slider/dist/tiny-slider.css';
import 'jquery-fancybox/source/scss/jquery.fancybox.scss';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';
import 'animate.css/animate.css';

import '../vendor/ytv/ytv.css';
import '../css/slideyslide.css';
import '../css/core.css';

import SlideySlide from './slideyslide';

var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin($);

(function($) {
  "use strict"; // Start of use strict

  var src = $('#masthead-modal video > source').attr('src');
  if (typeof src != "undefined") {
    var opt = {}
    var ext = src.split('.').pop().toLowerCase()
    ext = ext == 'mov' ? 'mp4' : ext
    opt[ext] = src
    $('header.video').vide(opt, {
      posterType: 'none',
      muted: true
    })
  }

  $('#masthead-modal').on('show.bs.modal', function(){
    $('header.video').data('vide').getVideoObject().pause()
    $('#masthead-modal video').get(0).play()
  }).on('hide.bs.modal', function(){
    $('header.video').data('vide').getVideoObject().play()
    $('#masthead-modal video').get(0).pause()
  })

  // $(window).scroll(slideySlide.trigger)
  // $(window).resize(slideySlide.trigger)
  // slideySlide.init()
  // $(document).scroll(slideySlide.trigger);
  // $(window).resize(slideySlide.trigger);
  $(document).ready(function(){setTimeout(() => new SlideySlide(), 1000)});

  var lazyConfig = {
    effectTime: 250,
    effect: 'fadeIn',
    visibleOnly: true
  }
  $('.lazy[data-src]').lazy(lazyConfig)
  var updateLazy = function($e) {
    var images = $e.find('.lazy[data-src]')
    if (images.length) {
      var lazy = images.data('plugin_lazy')
      if (typeof lazy == 'undefined') {
        images.lazy(lazyConfig)
      } else {
        lazy.update()
      }
    }
  }

  var updateShowMore = function($e) {
    var slide = $e.find('.tns-item.tns-slide-active .slide-container')
    var btn = $e.closest('.tns-outer').find('button.show-more')
    if (slide.height() > ($(window).height() - parseInt(slide.css('padding-top')))) {
      btn.show()
    } else {
      btn
        .hide()
        .closest('.tns-outer').find('.carousel').addClass('height-100vh')
    }
    if ($e.closest('.tns-outer').find('.carousel').is('.height-100vh')) {
      btn
        .text('Show more')
        .append($('<i class="fa fa-fw fa-angle-double-down"></i>'))
    } else {
      btn
        .text('Show less')
        .append($('<i class="fa fa-fw fa-angle-double-up"></i>'))
    }
  }

  $(".carousel.tns").each(function(index, element) {
    var options = $(element).data('tns')
    options['container'] = element
    var slider = tns(options)
    slider.events.on('indexChanged', function(info, eventName) {
      var $e = $(info.container)
      setTimeout(function() {
        updateLazy($e)
        updateShowMore($e)
      }, 500)
    })
  })
  $('.carousel[data-ride="carousel"]').on('slid.bs.carousel', function () {
    updateLazy($(this))
  })

  $('.carousel.height-100vh')
    .closest('.tns-outer')
    .find('button[data-action="stop"]')
      .addClass('show-more btn btn-primary btn-sm')
      .text('Show more')
      .append($('<i class="fa fa-fw fa-angle-double-down"></i>'))
      .on('click', function() {
        var $e = $(this).closest('.tns-outer').find('.carousel')
        if ($e.is('.height-100vh')) {
          $e.removeClass('height-100vh')
        } else {
          $e.addClass('height-100vh')
        }
        updateShowMore($e)
      })
  updateShowMore($('.carousel.tns'))

  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false
  });

  $('.popup-inline').magnificPopup({
    disableOn: 700,
    type: 'inline',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    callbacks: {
      open: function() {
        updateLazy(this.content)
      }
    }
  });

  $('.popup-gallery').each((index, element) => {
    $(element).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
      }
    });
  });

  // Masonry-style grid
  if ($(".masonry-grid").length > 0) {
    $(".masonry-grid").imagesLoaded(function() {
      setTimeout(() => {
        var iso = new Isotope('.masonry-grid', {
          filter: '*',
          itemSelector: ".gallery-grid__item",
          isAnimated: !0
        });
        $('.masonry-grid').data('isotope', iso);
      }, 100);
    });
  }

  $(".masonry-filter-list a").on("click", function(e) {
    e.preventDefault();
    var t = $(this).attr("data-filter"),
    i = $(this).closest(".masonry-grid-wrap").find(".masonry-grid");
    i.find(".gallery-grid__item");
    $(".masonry-filter-list a").removeClass("active");
    $(this).addClass("active");
    $(".masonry-grid").data('isotope').arrange({ filter: t });
  });

  // Pop-up
  $('.open-popup-link').on('click', function(e) {
    e.preventDefault();
    var $this = $(this),
        popupElem = $this.attr('href'),
        popupElem2 = $this.attr('data-href');

    $(popupElem).addClass('show');
    $(popupElem2).addClass('show');
  })

  $('.popup-content .close').on('click', function() {
    $(this).closest('.popup-wrap').removeClass('show');
  })

  $(document).mouseup(function (e){
    var div = $('.popup-content');
    if (!div.is(e.target)
        && div.has(e.target).length === 0) {
      div.closest('.popup-wrap').removeClass('show');
    }
  });

  // Closes the Responsive Menu on click
  $(document).on('click', function(event) {
    if ($(event.target).closest('.navbar').length == 0 &&
        $('.navbar-collapse.show').length > 0) {
      $('.navbar-toggler:visible').click();
    }
  })

})(jQuery); // End of use strict

function toggleMuted() {
    var v = $('header.video').data('vide').getVideoObject();
    v.muted = !v.muted;
    if (v.muted) {
        $('.audio-toggle .fa').removeClass('fa-volume-up').addClass('fa-volume-off').next().text('Turn sound on');
    } else {
        $('.audio-toggle .fa').removeClass('fa-volume-off').addClass('fa-volume-up').next().text('Turn sound off');
    }
}